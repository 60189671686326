import React, { useContext } from 'react'
import Helmet from 'react-helmet'
import { Redirect, Route, Switch } from 'react-router-dom'

import config from './config'
import CarPageProvider from './pages/CarPageProvider'
import DriverPageProvider from './pages/DriverPageProvider'
import Maintenance from './pages/Maintenance'
import PageValidator from './pages/PageValidator'
import Retrieval from './pages/Retrieval'
import { ProviderContext } from './Provider'
import { CheckoutRoute } from './routes/CheckoutRoute'
import { FullQuoteRoute } from './routes/FullQuoteRoute'
import {
  additionalDriverValidationInputs,
  carValidationInputs,
  coverValidationInputs,
  driverValidationInputs,
} from './utils/quoteValidation'
import { checkoutSteps } from './utils/steps'

const quoteComponent = import(/* webpackChunkName: "Quote" */ './pages/Quote')
const Quote = React.lazy(() => quoteComponent)

const checkoutCoverComponent = import(/* webpackChunkName: "Cover" */ './pages/CheckoutCover')
const CheckoutCover = React.lazy(() => checkoutCoverComponent)

const payComponent = import(/* webpackChunkName: "Pay" */ './pages/Pay')
const Pay = React.lazy(() => payComponent)

const howComponent = import(/* webpackChunkName: "How" */ './pages/How')
const How = React.lazy(() => howComponent)

const confirmComponent = import(/* webpackChunkName: "Confirm" */ './pages/Confirm')
const Confirm = React.lazy(() => confirmComponent)

const successComponent = import(/* webpackChunkName: "Confirm" */ './pages/Success')
const Success = React.lazy(() => successComponent)

// * New Quoteflow components
const DriverComponent = import(/* webpackChunkName: "Driver" */ './pages/Driver')
const Driver = React.lazy(() => DriverComponent)
const coverComponent = import(/* webpackChunkName: "Cover" */ './pages/Cover/Cover')
const Cover = React.lazy(() => coverComponent)

const sorryComponent = import(/* webpackChunkName: "Sorry" */ './pages/Sorry')
const Sorry = React.lazy(() => sorryComponent)

const sorryObdFitComponent = import(/* webpackChunkName: "SorryObdFit" */ './pages/SorryObdFit')
const SorryObdFit = React.lazy(() => sorryObdFitComponent)

const sorryFailIdScoreComponent = import(/* webpackChunkName: "SorryFailIdScore" */ './pages/SorryFailIDScore')
const SorryFailIdScore = React.lazy(() => sorryFailIdScoreComponent)

const CarComponent = import(/* webpackChunkName: "Car" */ './pages/Car')
const Car = React.lazy(() => CarComponent)

const timeoutComponent = import(/* webpackChunkName: "Timeout" */ './pages/Timeout')
const Timeout = React.lazy(() => timeoutComponent)

const timeoutRetrieveComponent = import(/* webpackChunkName: "Timeout" */ './pages/TimeoutRetrieve')
const TimeoutRetrieve = React.lazy(() => timeoutRetrieveComponent)

const errorComponent = import(/* webpackChunkName: "Error" */ './pages/Error')
const Error = React.lazy(() => errorComponent)

export const Router = () => {
  const { state } = useContext(ProviderContext)
  return (
    <>
      <Switch>
        {config.enableMaintenancePage && (
          <>
            <Redirect from="*" to="/maintenance" />

            <Route path="/maintenance">
              <Maintenance />
            </Route>
          </>
        )}
        <Route path="/retrieval">
          <Helmet>
            <title>Retrieval</title>
          </Helmet>
          <Retrieval />
        </Route>
        <Route path="/retrieve">
          <Helmet>
            <title>Retrieve</title>
          </Helmet>
          <Retrieval cold={true} />
        </Route>
        <Route exact path="/timeout">
          <Helmet>
            <title>Timeout</title>
          </Helmet>
          <Timeout
            pageTitle="Timeout"
            gaPageName="/ga-virtual/timeout/no-retrieve"
            onLogoClick={() => (window.location.href = `${config.getBrochureOrigin}`)}
            hideStickyPricing
            hideSaveQuote
          />
        </Route>
        <Route path="/timeout/retrieve">
          <Helmet>
            <title>Timeout Retrieve</title>
          </Helmet>
          <TimeoutRetrieve
            pageTitle="Timeout Retrieve"
            gaPageName="/ga-virtual/timeout/retrieve"
            onLogoClick={() => (window.location.href = `${config.getBrochureOrigin}`)}
            hideStickyPricing
            hideSaveQuote
          />
        </Route>

        <Route path="/sorry/obd-fit">
          <Helmet>
            <title>Sorry</title>
          </Helmet>
          <SorryObdFit
            gaPageName="/ga-virtual/sorry/obd-fit"
            hideStickyPricing
            hideSaveQuote
            pageTitle="Sorry"
            backButtonUrl="/details/car"
            backButtonLabel="Try another car"
          />
        </Route>

        <Route path="/sorry/details">
          <Helmet>
            <title>Sorry</title>
          </Helmet>
          <SorryFailIdScore
            gaPageName="/ga-virtual/sorry/details"
            hideStickyPricing
            hideSaveQuote
            pageTitle="Sorry"
            backButtonUrl="/details/driver"
            backButtonLabel="Edit my details"
          />
        </Route>

        <Route path="/sorry">
          <Helmet>
            <title>Sorry</title>
          </Helmet>
          <Sorry
            gaPageName="/ga-virtual/checkout/sorry"
            hideStickyPricing
            hideSaveQuote
            pageTitle="Sorry"
            backButtonUrl="/details/car"
            backButtonLabel="Edit my details"
          />
        </Route>
        {
          // **************************************** New Quote Flow
        }

        <FullQuoteRoute path="/details/car">
          <PageValidator pageName="car" inputsToValidate={carValidationInputs}>
            <CarPageProvider>
              <Car
                gaPageName="/ga-virtual/details/car"
                pageName="car"
                hideSaveQuote
                hideStickyPricing
                pageTitle="Car"
                backButtonUrl=""
                backButtonLabel=""
              />
            </CarPageProvider>
          </PageValidator>
        </FullQuoteRoute>
        <FullQuoteRoute path="/details/driver">
          <PageValidator
            pageName="driver"
            inputsToValidate={driverValidationInputs}
            isAnArray
            currentIndex={state.selectedDriverIndex}
            differentInputsToValidateForArray={additionalDriverValidationInputs}
          >
            <DriverPageProvider>
              <Driver
                gaPageName="/ga-virtual/details/driver"
                pageName="driver"
                hideSaveQuote
                hideStickyPricing
                pageTitle="Driver"
                backButtonUrl="car"
                backButtonLabel="Back to car"
              />
            </DriverPageProvider>
          </PageValidator>
        </FullQuoteRoute>
        <FullQuoteRoute path="/details/cover">
          <PageValidator pageName="cover" inputsToValidate={coverValidationInputs}>
            <Cover
              gaPageName="/ga-virtual/details/cover"
              pageName="firstCover"
              hideSaveQuote
              hideStickyPricing
              pageTitle="Cover"
              backButtonUrl="driver"
              backButtonLabel="Driver"
            />
          </PageValidator>
          /
        </FullQuoteRoute>

        {
          // **************************************** New Quote Flow
        }
        <CheckoutRoute path="/quote">
          <Quote
            pageTitle="Quote"
            gaPageName="/ga-virtual/checkout/quote"
            backButtonUrl={`details/car`}
            backButtonLabel="Edit my details"
            hideStickyPricing={false}
            nextButtonUrl="cover"
            priceQuoteOnPageLoad={true}
            steps={checkoutSteps}
            stepIndex={0}
            showPromo={true}
          />
        </CheckoutRoute>
        <CheckoutRoute path="/cover">
          <CheckoutCover
            pageTitle="Cover"
            gaPageName="/ga-virtual/checkout/cover"
            backButtonLabel="Back to Quote"
            backButtonUrl="quote"
            hideStickyPricing={false}
            nextButtonUrl="how"
            steps={checkoutSteps}
            stepIndex={1}
          />
        </CheckoutRoute>
        <CheckoutRoute path="/how">
          <How
            pageTitle="How"
            gaPageName="/ga-virtual/checkout/how"
            backButtonLabel="Back to Cover"
            hideStickyPricing={false}
            nextButtonUrl="confirm"
            backButtonUrl="cover"
            steps={checkoutSteps}
            stepIndex={2}
          />
        </CheckoutRoute>
        <CheckoutRoute path="/confirm">
          <Confirm
            pageTitle="Confirm"
            gaPageName="/ga-virtual/checkout/confirm"
            backButtonLabel="Back to How"
            hideStickyPricing={false}
            nextButtonUrl="pay"
            backButtonUrl="how"
            steps={checkoutSteps}
            stepIndex={3}
          />
        </CheckoutRoute>
        <CheckoutRoute path="/pay">
          <Pay
            pageTitle="Pay"
            gaPageName="/ga-virtual/checkout/pay"
            backButtonLabel="Back to confirm"
            hideStickyPricing={true}
            backButtonUrl="confirm"
            steps={checkoutSteps}
            stepIndex={4}
          />
        </CheckoutRoute>
        <CheckoutRoute path="/success">
          <Success
            pageTitle="Success"
            gaPageName="/ga-virtual/checkout/success"
            hideStickyPricing={true}
            hideSaveQuote={true}
          />
        </CheckoutRoute>
        <Redirect exact from="/" to="quote" />
        <Redirect exact from="/v2" to="quote" />
        <Redirect exact from="/explainer" to="quote" />
        <Route path="/">
          <Helmet>
            <title>Oops</title>
          </Helmet>
          <Error
            pageTitle="Oops"
            gaPageName="/ga-virtual/oops"
            onLogoClick={() => (window.location.href = `${config.getBrochureOrigin}`)}
            hideStickyPricing
            hideSaveQuote
          />
        </Route>
      </Switch>
    </>
  )
}
