import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { datadogRum } from '@datadog/browser-rum'
import Cookie from 'js-cookie'
import queryString from 'query-string'

import config, { isProd } from './config'

const querystring = queryString.parse(document.location.search)

// Shared with tests too.
export const sharedApolloCache = new InMemoryCache()

const httpLink = new HttpLink({
  uri: config.quoteFlowAPIOrigin + '/graphql',
  headers: {
    'quote-token':
      querystring[config.quoteTokenCookieName] ||
      querystring.quote_token || // TODO: Remove
      Cookie.get(config.quoteTokenCookieName) ||
      Cookie.get('quoteToken') || // TODO: Remove
      'missing-quote-token',
  },
})

const unauthenticatedLink = onError(({ graphQLErrors, operation, networkError }) => {
  const quoteId = Cookie.get(config.quoteIdCookieName)
  graphQLErrors?.forEach(error => {
    const rumError = new Error(`${operation.operationName}: ${error.message}`)
    datadogRum.addError(rumError)
    switch (error.message) {
      case 'Invalid quoteToken':
      case 'Missing quoteToken':
        if (quoteId) {
          window.location.href = `${config.checkoutOrigin}/retrieval?quote_id=${quoteId}`
        } else {
          window.location.href = `${config.checkoutOrigin}/details/car`
        }
        break
    }
  })
  if (networkError) window.location.href = '/sorry'
})

export const client = new ApolloClient({
  link: unauthenticatedLink.concat(httpLink),
  cache: sharedApolloCache,
  credentials: 'include',
  name: 'quoteflow-ui',
  version: process.env.REACT_APP_IMAGE_TAG,
  connectToDevTools: !isProd,
})
