import { quoteId } from '../cache'
import { client } from '../client'
import { QuoteDocument, QuoteQuery, QuoteQueryVariables } from '../generated/graphql'

export const useQuote = () => {
  const id = quoteId()
  if (!id) return null

  const data = client.readQuery<QuoteQuery, QuoteQueryVariables>({
    query: QuoteDocument,
    variables: { id },
  })

  return data?.quote || null
}
