import React, { createContext, useContext, useEffect, useState } from 'react'

import { ErrorBanner } from '../components/ErrorBanner'
import { genericErrorCopy } from '../constants'
import useChatWidget from '../hooks/useChatWidget'
import { ProviderContext } from '../Provider'
export interface isValidProps {
  [key: string]: any
}

interface updateValidInputsProps {
  field: string
  value: boolean
  index?: number
}

export interface PageValidatorContextType {
  validInputs: isValidProps
  setValidInputs: (value: isValidProps) => void
  getKeyByValue: (object: any, value: boolean) => string | undefined
  validateTransition: () => boolean
  showErrorBanner: boolean
  setShowErrorBanner: (value: boolean) => void
  notAnsweredMessage: string
  updateValidInputs: ({ field, value }: updateValidInputsProps) => void
  pageName: string
  removeValidationIndex: (index: number) => void
}

export const defaultPageValidatorContext = {
  validInputs: {},
  setValidInputs: () => {},
  getKeyByValue: () => '',
  validateTransition: () => false,
  showErrorBanner: false,
  setShowErrorBanner: () => {},
  notAnsweredMessage: '',
  updateValidInputs: () => {},
  pageName: '',
  removeValidationIndex: () => {},
}

export const PageValidatorContext = createContext<PageValidatorContextType>(defaultPageValidatorContext)

export interface PageValidatorProps {
  children: React.ReactNode
  inputsToValidate: isValidProps
  pageName: string
  isAnArray?: boolean
  currentIndex?: number
  differentInputsToValidateForArray?: isValidProps
}

const PageValidator = ({
  children,
  inputsToValidate,
  pageName,
  isAnArray = false,
  currentIndex = 0,
  differentInputsToValidateForArray,
}: PageValidatorProps) => {
  const { simpleStateUpdater, state } = useContext(ProviderContext)
  const { updateChatOffset } = useChatWidget()
  const [validInputs, setValidInputs] = useState<isValidProps>({})
  const [showErrorBanner, setShowErrorBanner] = useState(false)
  const [numberOfErrors, setNumberOfErrors] = useState(0)
  const [nextError, setNextError] = useState('')
  const notAnsweredMessage = genericErrorCopy
  const getKeyByValue = (object: any, value: boolean) => Object.keys(object).find(key => object[key] === value)

  const scrollToElement = (element: string) => {
    const target = document.getElementById(element)
    target?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (!validInputs[pageName]) {
      setValidInputs({
        ...state.previousValidPage,
        [pageName]: isAnArray ? [{ ...inputsToValidate }] : inputsToValidate,
      })
    }
  }, [pageName])

  useEffect(() => {
    updateNumberOfErrors()
  }, [validInputs])

  useEffect(() => {
    updateChatOffset({
      vertical: !showErrorBanner || numberOfErrors === 0 ? '0' : '72px',
    })
    const cookieYesIcon: HTMLElement | null = document.querySelector('.cky-revisit-bottom-left')
    if (cookieYesIcon) cookieYesIcon.style.bottom = !showErrorBanner || numberOfErrors === 0 ? '10px' : '82px'
  }, [showErrorBanner, numberOfErrors])

  const updateValidInputs = ({ field, value, index = 0 }: updateValidInputsProps) => {
    if (!validInputs[pageName]) {
      validInputs[pageName] = isAnArray ? [{ ...inputsToValidate }] : inputsToValidate
    }
    const allValidInputs = validInputs
    if (isAnArray) {
      if (!validInputs[pageName][index]) {
        allValidInputs[pageName][index] =
          differentInputsToValidateForArray && index > 0
            ? { ...differentInputsToValidateForArray }
            : { ...inputsToValidate }
        setValidInputs(allValidInputs)
      }

      allValidInputs[pageName][index][field] = value
    } else {
      allValidInputs[pageName][field] = value
    }

    setValidInputs({
      ...allValidInputs,
      [pageName]: {
        ...allValidInputs[pageName],
      },
    })
  }

  const removeValidationIndex = (index: number) => {
    const allValidInputs = validInputs
    if (validInputs[pageName][index]) {
      delete allValidInputs[pageName][index]
      setValidInputs(allValidInputs)
    }
  }

  const updateNumberOfErrors = () => {
    if (isAnArray) {
      if (validInputs[pageName] && validInputs[pageName][currentIndex]) {
        const allInvalidValues = Object.keys(validInputs[pageName][currentIndex]).filter(
          key => !validInputs[pageName][currentIndex][key],
        )
        setNumberOfErrors(allInvalidValues?.length)
        setNextError(allInvalidValues[0])
      }
    } else {
      if (validInputs[pageName]) {
        const allInvalidValues = Object.keys(validInputs[pageName]).filter(key => !validInputs[pageName][key])
        setNumberOfErrors(allInvalidValues?.length)
        setNextError(allInvalidValues[0])
      }
    }
  }

  const validateTransition = () => {
    if (!isAnArray && validInputs[pageName]) {
      if (Object.values(validInputs[pageName]).includes(false)) {
        if (nextError) {
          setShowErrorBanner(true)
          scrollToElement(nextError)
        }
        return false
      }
    }
    let foundError = false
    if (isAnArray && validInputs[pageName]) {
      if (Object.values(validInputs[pageName][currentIndex]).includes(false)) {
        if (nextError) {
          setShowErrorBanner(true)
        }
        foundError = true
      }
    }

    if (foundError) {
      scrollToElement(nextError)
      return false
    }

    simpleStateUpdater([
      {
        state: 'previousValidPage',
        value: { [pageName]: validInputs[pageName] },
      },
    ])

    if (showErrorBanner) setShowErrorBanner(false)
    return true
  }

  return (
    <PageValidatorContext.Provider
      value={{
        pageName,
        validInputs: validInputs[pageName],
        setValidInputs,
        getKeyByValue,
        validateTransition,
        showErrorBanner,
        setShowErrorBanner,
        notAnsweredMessage,
        updateValidInputs,
        removeValidationIndex,
      }}
    >
      {children}
      {showErrorBanner && numberOfErrors > 0 && (
        <ErrorBanner handleButtonClick={() => scrollToElement(nextError)} numberOfErrors={numberOfErrors} />
      )}
    </PageValidatorContext.Provider>
  )
}
export const usePreQuoteContext = () => useContext(PageValidatorContext)

export default PageValidator
