import { DataLayer } from '@by-miles/data-layer'
import { isEmpty } from 'lodash'
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { quoteId } from '../cache'
import {
  ModificationsQuery,
  OwnersQuery,
  SetVehicle,
  useModificationsQuery,
  useOwnersQuery,
} from '../generated/graphql'
import { updateQuoteMutation } from '../hooks'
import { ProviderContext } from '../Provider'
import { usePreQuoteContext } from './PageValidator'

export interface CarPageContextType {
  updatedCarDetails: SetVehicle
  setUpdatedCarDetails: (value: SetVehicle) => void
  handleUpdateCarDetails: (value: SetVehicle) => void
  validateAndSave: () => boolean
  ownersData: OwnersQuery | undefined
  modificationsData: ModificationsQuery | undefined
}

export const defaultCarContext = {
  updatedCarDetails: {},
  setUpdatedCarDetails: () => {},
  handleUpdateCarDetails: () => {},
  validateAndSave: () => false,
  ownersData: undefined,
  modificationsData: undefined,
}

export const CarContext = createContext<CarPageContextType>(defaultCarContext)

interface CarPageProviderProps {
  children: ReactNode
}

const CarPageProvider = ({ children }: CarPageProviderProps) => {
  const { validateTransition } = usePreQuoteContext()
  const { updateQuote } = updateQuoteMutation()
  const { data: modificationsData } = useModificationsQuery()
  const { data: ownersData } = useOwnersQuery()
  const history = useHistory()
  const { setIsFetching } = useContext(ProviderContext)

  const [updatedCarDetails, setUpdatedCarDetails] = useState<SetVehicle>({})

  const id = quoteId() || ''

  useEffect(() => {
    setIsFetching(!ownersData || !modificationsData)
  }, [ownersData, modificationsData])

  const handleSaveCarDetais = async () => {
    setIsFetching(true)
    await updateQuote({
      variables: {
        id,
        actions: [
          {
            setVehicle: updatedCarDetails,
          },
        ],
      },
    })
    setIsFetching(false)
    history.push('/details/driver')
  }

  const validateAndSave = () => {
    const valid = validateTransition()
    DataLayer.customEvent({
      eventName: 'submit',
      section: 'car',
      eventType: 'form tracking',
    })
    if (valid) {
      if (isEmpty(updatedCarDetails)) {
        history.push('/details/driver')
      } else {
        handleSaveCarDetais()
      }
    }
    return valid
  }

  const handleUpdateCarDetails = (update: SetVehicle) => {
    setUpdatedCarDetails({
      ...updatedCarDetails,
      ...update,
    })
  }

  return (
    <CarContext.Provider
      value={{
        updatedCarDetails,
        setUpdatedCarDetails,
        handleUpdateCarDetails,
        validateAndSave,
        ownersData,
        modificationsData,
      }}
    >
      {children}
    </CarContext.Provider>
  )
}

export const useCarPageContext = () => useContext(CarContext)

export default CarPageProvider
